import { useCallback, useMemo, useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { FakeLink } from '@change/design-system/components/actions';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useRichMediaTransformers } from 'src/app/shared/hooks/richMedia';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';
import { useDescriptionTracking } from '../hooks/tracking/description';

const DESCRIPTION_LENGTH_THRESHOLD = 350;
const COLLAPSED_STYLE = {
	height: '200px',
	overflow: 'hidden',
	backgroundImage: 'linear-gradient(black 75%, white)',
	color: 'transparent',
	backgroundClip: 'text',
};
const EXPANDED_STYLE = {
	height: 'auto',
};

export function DescriptionContainerViewportSmall(): JSX.Element {
	const { trackClickLink } = useDescriptionTracking();
	const { track } = useCampaignTracking();
	const details = usePetitionDetails();
	const { decodeRichMediaTransformer } = useRichMediaTransformers();
	const trackingData = useMemo(() => ({ petition_id: details.id, action: 'expanded' }), [details]);

	const isDescriptionTooLong = useMemo(
		() => details && details.description && details.description.length > DESCRIPTION_LENGTH_THRESHOLD,
		[details],
	);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const handleExpand = useCallback(() => {
		setIsCollapsed(false);
		track('gamma_petition_description_show_full_text_click', trackingData);
	}, [setIsCollapsed, track, trackingData]);

	const htmlTransformers = useMemo(() => [decodeRichMediaTransformer], [decodeRichMediaTransformer]);

	return (
		<Box>
			{details && details.description && (
				<Box>
					<h2>
						<Translate value="corgi.petition_gamma.details.description_title" />
					</h2>

					<Box data-qa="description-content">
						<Box
							data-testid="description-html-content"
							sx={isCollapsed && isDescriptionTooLong ? COLLAPSED_STYLE : EXPANDED_STYLE}
						>
							<Html
								breakWord
								html={details.description}
								sx={{
									iframe: {
										width: '100%',
										height: 'auto',
										aspectRatio: '16/9',
									},
								}}
								transformers={htmlTransformers}
								onClickLink={trackClickLink}
							/>
						</Box>

						{isDescriptionTooLong && (
							<Box mt={8}>
								<FakeLink
									onClick={handleExpand}
									data-testid="show-full-text"
									sx={{
										display: isCollapsed ? 'inline-block' : 'none',
										color: 'black',
									}}
								>
									<Translate value="corgi.petition_gamma.details.description_show_full_text" />
								</FakeLink>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
}
